@use "sass:math";

:root {
  --y-header: 0;
  --grid-cols: 48;
  --grid: #{math.div(100vw, 48)};

  --padding-xl: #{math.div(120px, 16px) * 1rem};
  --padding-l: #{math.div(90px, 16px) * 1rem};
  --padding-m: #{math.div(60px, 16px) * 1rem};
  --padding-s: #{math.div(30px, 16px) * 1rem};
  --padding-xs: #{math.div(15px, 16px) * 1rem};
  --padding-xxs: #{math.div(8px, 16px) * 1rem};

  --header-height:#{math.div(77px, 16px) * 1rem};
}

@media (max-width: $smartphone) {
  :root {}
}

@function gridSize($n) {
  @return calc(#{$n} * var(--grid));
}
