:root {
  --white: #ffffff;
  --black: #000000;
  --grey: #949494;
  --blue: #0000F8;
  --green: #00F92D;

  --primary: var(--black);
  --secondary: var(--white);

  --primary-bg: var(--white);
  --primary-color: var(--black);

  --assertive: #ff00ff;
  --focus: #ff00ff;
  --color-error: #ff0000;
  --color-sucess: #4dc247;
}