//↗ → ← ↖

@mixin title-with-label() {
    --width-deco: var(--font-size);
    --height-deco: calc(var(--font-size) * .9);
    --border-size: clamp(1px, .055em, 20px);
    --color: var(--primary-color);
    --time: .4s;
    --font-size-deco: .5em;
    --opacity: 0;

    &.--double {
        --width-deco: calc(var(--font-size) * 2);
    }

    &.--big-icon {
        --font-size-deco: 1em;
    }

    &.--show {
        --opacity: 1;
    }

    @include basic-a();
    position: relative;
    
    color: var(--color);
    font-size: var(--font-size);
    padding-left: var(--width-deco);
            
    > .deco {
        opacity: var(--opacity);
        position: absolute;
        display: block;
        top: .015em;
        left: 0;
        width: var(--width-deco);
        height: var(--height-deco);
        border-bottom: var(--border-size) solid currentColor;  
        
        .icon {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            top: 0;
            right: 0;
            width: 0;
            height: var(--width-deco); 
            transform: rotate(90deg);
            transform-origin: 100% 0;
            @include font-serif();
            font-size: var(--font-size-deco);
            line-height: 1;
            background-color: aqua
        }

        .mask {
            position: absolute;
            @include z-index(2);
            top:-.03em;
            left:0;
            width: calc(100% + .05em);
            height: 117.5%;
            background-color: var(--color);
            transform: scale3d(1,1,1);
            transform-origin: top right;
        }
    }

    > .text {
        display: block;
        @include font-sans();
        line-height: .85;
        padding-left: .05em;
        padding-top: 0;
    }

    .masked-item {
        position: relative;
        opacity: 0;
        display: inline-block;
        margin-right: .1em;
    
        > .text {position: relative;}
        > .bg,
        > .mask {
            position: absolute;
            @include z-index(2);
            top:0;
            left:0;
            width: calc(100% + .05em);
            height: 115%;
            background-color: var(--color);
            transform: scale3d(1,1,1);
            transform-origin: top right;
        }
    }
}



.title-with-label {
    @include title-with-label();
}
