//↗ → ← ↖

@mixin link-with-mask() {
    --color: var(--primary-color);
    --bg-color: var(--grey);
    --border-size: clamp(1px, .055em, 20px);
    --width-deco: 1em;
    --size-text: 100px;
    --size-hide: var(--width-deco);
    --size-show: calc(var(--width-deco) + var(--size-text) + .05em);
    --width: var(--size-hide);
    --ease: var(--ease-out-quad);
    --steps: 5;
    --time: calc(var(--steps) * .1s);
    --delay-mask: .2s;
    --delay-bg: .7s;
    --ico-rotation: 90deg;
    --offset-x: 0;
    --mask-scale: 1;
    --bg-scale: 1;
     
    @include basic-a();
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: var(--width);
    
    color: var(--color);
    font-size: var(--font-size);
    padding-left: var(--width-deco);

    /* ANIMATION */
    transition: width .1s;
    //transition-duration: var(--time);
    //transition-timing-function: steps(var(--steps), end);

    > .deco {
        position: absolute;
        display: block;
        bottom: .1em;
        left: 0;
        width: var(--width-deco);
        height: var(--width-deco);
        border-bottom: var(--border-size) solid currentColor;   

        .icon {
            position: absolute;
            display: block;
            bottom:.1em;
            left:.1em;
            text-align: right;
            width: 100%;
            height: 100%;
            transform: rotate(var(--ico-rotation)) translate3d(var(--offset-x),0,0);
            @include font-serif();
            line-height: 1;
            text-transform: lowercase;
            //transition: transform .3s ease-in-out;
        }
    }

    &.--open > .text {
        &::before,
        &::after {
            transform-origin: top left;
        }
    }

    > .text {
        position: relative;
        @include font-sans();
        line-height: 1;
        padding-left: .05em;
        word-wrap: none;
        white-space: nowrap;

        > span {
            position: relative;
        }

        &::before,
        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: .04em;
            width: 100%;
            height: 100%;
            background-color: var(--color);
            transform: scale3d(var(--mask-scale), 1, 1);
            transform-origin: top right;
            transition: transform .0s var(--ease-cuchillo-in-out) .1s;
        }

        &::before {
            transform: scale3d(var(--bg-scale), 1, 1);
            background-color: var(--bg-color);
            transition: transform .0s var(--ease-cuchillo-in-out) .1s;
        }
    }

    &.--open {
        --width: var(--size-show);
        --mask-scale: 0;
        --bg-scale: 0;
        --delay-bg: 0s;
    }
     
    &:hover {
        --width: var(--size-show);
        --mask-scale: 0;
        --bg-scale: 0;

        &.--open {
            --bg-scale: 1;
        }
        
        > .text {
            &::after {
                transition: transform .4s var(--ease-cuchillo-in-out) var(--delay-mask);
            }
            &::before {
                transition: transform .4s var(--ease-cuchillo-in-out) var(--delay-bg);
            }
        }
    }

    &.--active,
    &.__link-active {
        --pos: var(--pos-hover);
        pointer-events: none;
    }

    /// TOUCH
    @include isTouch() {
        overflow: visible;

        &:before {
            opacity: 0;
            transform: translateX(0);
        }

        > span {
            transform: translateX(0);
        }

        &:hover {
            &:before {
            opacity: 1;
            }
        }
    }
}

.link-with-mask {
    @include link-with-mask();
}
