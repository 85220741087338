
.block-contact {
    --bg: var(--green);
    --font-size: var(--font-size-xl);
    --color: var(--black);
    --padding: var(--grid);
    --padding-bottom: calc(var(--font-size) * .8);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-l);
        --padding-bottom: calc(var(--font-size) * 2.5);
    }
}

.block-contact {
    overflow: hidden;
    background-color: var(--bg);
    padding: var(--padding) var(--padding) var(--padding-bottom);

    @include font-sans();
    line-height: .85;
    font-size: var(--font-size);

    a {
        --color: var(--blue);
        @include basic-a();
        color: var(--color);

        .--grey {
            color: var(--grey);
        }
    }

    .title-with-label {
        --color: var(--black);
    }

    .--relative {
        position: relative;
    }

    h3 {
        margin-top: 1em;
    }

    .panel-white {
        position: absolute;
        width: 100%;
        height: 2em;
        bottom: 7.4em;
        right:0;
        background-color: var(--white);
    }

    .panel-grey {
        position: absolute;
        width: 50.35%;
        height: 5.6em;
        bottom: 4.4em;
        right:0;
        background-color: var(--grey);
    }

    .panel-black {
        position: absolute;
        width: 49.65%;
        height: 2.6em;
        bottom: 0;
        left:0;
        background-color: black;
    }
}

.block-contact__logo {
    --top: 0;
    --left: 0;
    --width: 100%;
    --height: 100vh;
    
    position: absolute;
    top: var(--top);
    left: var(--left);
    width: var(--width);
    height: var(--height);
    pointer-events: none;

    svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        fill: white;
    }
}
