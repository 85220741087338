#Interface__Canvas {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  @include z-index($z-index-interface);
  pointer-events: none;
}

body > canvas {
  position: fixed;
  @include z-index(0);
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
}

#SpriteSheetTexture {
  @include z-index($z-index-interface);
}