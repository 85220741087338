#Background {
  --top: 0;
  --left: 0;
  --width: 100%;
  --height: 100%;
  
  position: fixed;
  @include z-index($z-index-bg);
  top: var(--top);
  left: var(--left);
  width: var(--width);
  height: var(--height);
  pointer-events: none;
  
  > span {
    --bg: #fff;

    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    transform: scale3D(0,0,1);
    transition: background-color .2s ease-in;
    background-color: var(--bg);

    &.--blue {--bg: var(--blue);}
    &.--green {--bg: var(--green);}
    &.--grey {--bg: var(--grey);}
  }
}

#SceneParticles {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
}