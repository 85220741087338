
.block-project-info {
    --display: grid;
    --font-size: var(--font-size-base);
    --font-size-title: var(--font-size-base);
    --font-size-description: var(--font-size-xl);
    --padding-top: #{gridSize(12)};
    --padding-h: #{gridSize(1)};
    --padding-bottom: #{gridSize(12)};

    @media (max-width: $smartphone) {
        position: relative;
        @include z-index(4);
        --padding-top: #{gridSize(24)};
        --display: block;
        --font-size-description: var(--font-size-l);
    }
}

.block-project-info {
    width: 100%;
    min-height: 100vh;
    font-size: var(--font-size);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom) ;
    display: var(--display);
    grid-template-columns: repeat(3, 1fr);
    column-gap: #{gridSize(1)};

    .col-left {
        grid-column: 1 / 1;
    }

    .col-right {
        grid-column: 2 / 4;
    }

    .col-text {
        grid-column: 1 / 4;
    }

    @media (max-width: $smartphone) {
        display: block;
    }
}

.block-project-info ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1em 0;

    > li {
        @include font-sans();
        display: inline-flex;
        margin-bottom: .1em;
        pointer-events: all;

        > .label {
            margin-right: .1em;
        }
    }

    a {
        &:hover {
            background-color: var(--black);
            > * {
                --color: var(--white);
                color: var(--color);
            }
        }
    }
}

.block-project-info__a {
    @include basic-a();
    text-decoration: none;
    width: 100%;
}

.block-project-info__title {
    @include font-serif();
    font-size: var(--font-size-title);
    text-transform: uppercase;
    letter-spacing: -.23em;
    margin-left: -.23em;
    &::before {
        content: '[';
    }
    &::after {
        content: ']';
    }
    
    &.masked-item > .mask {
        left: .23em;
    }
}

.block-project-info__description-large {
    @include font-sans();
    margin: 1em 0;
}

.block-project-info__description {
    @include font-sans();

    &.--blue { color: var(--blue); }
    &.--grey { color: var(--grey); }
    &.--green { color: var(--green); }

    line-height: .85;
    font-size: var(--font-size-description);
}