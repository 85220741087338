@use "sass:math";

.billboard-text {
    --width: 100%;
    --color: var(--primary-color);
    --bg: transparent;
    --padding: var(--grid);
    --font-size: var(--font-size-l);

    @media (max-width: $smartphone) {
        --font-size: 32px;
    }
}

.palette-primary .billboard-text::before {
    content: '';
    position: absolute;
    z-index: -1;
    /*background: rgb(255,255,255);
    background: linear-gradient(0deg, 
        rgba(255,255,255,0) 0%, 
        rgba(255,255,255,0) 20%,
        rgba(255,255,255,.4) 40%, 
        rgba(255,255,255,.4) 100%);*/
    width: 100%;
    height: 100%;
    top:0;
    left:0;
}

.billboard-text {
    position: relative;
    padding: var(--padding);
    margin: 0;

    @include font-sans();
    font-size: var(--font-size);
    line-height: 1;

    color: var(--color);
    background-color: var(--bg);    

    .break-line {
        display: block;
        height: 1em;
    }

    .billboard-item {
        position: relative;
        opacity: 0;

        > .text {position: relative;}
        > .bg,
        > .mask {
            position: absolute;
            @include z-index(2);
            top:0;
            left:0;
            width: calc(100% + .05em);
            height: 100%;
            background-color: var(--color);
            transform: scale3d(1,1,1);
            transform-origin: top right;
        }

        > .bg {
            background-color: var(--green);
            @include z-index(0);
        }

        &.--arrow {
            padding-right: .5em;
        }

        &.--arrow::after {
            position: absolute;
            @include z-index(1);
            right: 0;
            bottom: .16em;
            content: '↗';
            @include font-serif();
            font-size: 1em;
            color: var(--blue);
        }
    }
}
