@use "sass:math";

#Header {
    --color: var(--primary-color);
    --bg: transparent;
    --font-size: var(--font-size-l);
    --font-size-title: var(--font-size-xl);
    
    @media (max-width: $smartphone) {
      --font-size-title: var(--font-size-l);
    }
}

#Header .header__title {
    @include z-index($z-index-header-title);
    position: fixed;
    width: var(--width);
    left: var(--grid);
    top: var(--grid);
    --font-size: var(--font-size-title)
}

.header__nav {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    left: var(--grid);
    bottom: var(--grid);
    opacity: 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);
}

.header__nav > .mask {
  position: absolute;
  @include z-index(2);
  top:0;
  left:0;
  width: calc(100% + .05em);
  height: 100%;
  background-color: var(--color);
  transform: scale3d(1,1,1);
  transform-origin: top right;
}

.header__nav > ul,
.header__nav > nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;

  @media (max-width: $smartphone) {
    flex-direction: column;
  }

  a {
    //text-transform: lowercase;
  }

  a[aria-current='page'] {
    display: none;
  }
}

.header__nav > ul > li {
  position: relative;
  flex: 0 0 auto;
  margin-right: .35em;

  &:nth-child(1) {a{--bg-color: var(--grey);}}
  &:nth-child(2) {a{--bg-color: var(--blue);}}
  &:nth-child(3) {a{--bg-color: var(--green);}}
  &:nth-child(4) {a{--bg-color: var(--blue);}}
  &:nth-child(5) {a{--bg-color: var(--grey);}}
  &:nth-child(6) {a{--bg-color: var(--green);}}
  &:nth-child(7) {a{--bg-color: var(--blue);}}
}

@media (max-width: $smartphone) {
  .header__nav {
    --font-size: #{math.div(16px, 16px) * 1rem};

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;

    > .title {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    > .date {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      align-self: flex-end;
    }
  }

  .header__nav > nav {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    grid-template-columns: repeat(1, 1fr);

    text-align: right;

    a:not(:last-of-type) {
      margin-bottom: .4em;
    }
  }
}

@media (max-width: $smartphone) {
  .header__nav > ul > li {
    order: 2;
    margin-top: .1em;

    &.--open {
      order: 1;
    }
  }
  .header__nav > ul > li > .link-with-mask{
    --width: var(--size-show);
    --mask-scale: 0;
    --bg-scale: 0;
    --delay-bg: 0s;

    

    > .text {
      &::before,
      &::after {
          transform-origin: top left;
      }
    }

    &:hover {
      &.--open {
        --bg-scale: 1;
      }
    }
  }
}
