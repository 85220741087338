.default {
    &:not(.project) {
        pointer-events: all;
    }
}

.about .block-slider-photos {
    margin-top: 25vh;
}

.about .billboard-text {
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
}

@media (max-width: $smartphone) {
    .home {
        position: relative;
        @include z-index(3)
    }

    .about .block-slider-photos {
        margin-top: 5vh;
    }
}