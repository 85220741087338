
.block-project-cover {
    --position: absolute;
    --ratio: 1;
    --scale: 1;
    --width: calc(38vw * var(--ratio));
    --z-index: 3;
    --z-transform: 3px;
    --top:0;
    --left:0;

    @media (max-width: $smartphone) {
        --width: 70vw;
        --z-index: 2;
        --z-transform: 2px;
        --top:10vw;
        --left:25vw;
    }
}

#Header > .block-project-cover {
    z-index: var(--z-index);
    opacity: 0;
    transform: 
        scale3d(var(--scale), var(--scale), var(--scale))
        translate3d(0,0,var(--z-transform));
    --position: fixed;

    figure.--hide {
        display: none;
    }
}

.block-project-cover {
    position: var(--position);
    top:var(--top);
    left:var(--left);
    width: var(--width);
    height: auto;
    user-select: none;
    display: grid;

    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    img {
        -webkit-user-drag: none;
    }

    > div::after {
        position: absolute;
        content: '';
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #ffffff, $alpha: 0);
    }
}