.block-visor-videos {
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;

    > video {
        z-index: -1;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0;
    }

    &:before {
        @include pseudo-element-absolute();
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-image: url($images-path + "scanline.png");
        background-size: 1px 4px;
        background-repeat: repeat;
        opacity: .1;
    }
}
