//↗ → ← ↖

@mixin link-with-label() {
    --font-size: inherit;
    --width-deco: 1em;
    --b-width: 100%;
    --b-left: 0;
    --b-color: var(--white);
    --b-bg: var(--grey);
    --border-size: clamp(1px, .055em, 20px);
    --color: var(--primary-color);
    --pos: 0;
    --pos-hover: 1em;
    --ease: var(--ease-out-quad);
    --time: .4s;

    --hover-scale: 3;
    --hover-left: 60%;
    --transform-origin: 0%;

    @include basic-a();
    position: relative;
    overflow: hidden;
    
    color: var(--color);
    font-size: var(--font-size);
    padding-left: var(--width-deco);
    white-space: nowrap;

    &[href=""] {
        pointer-events: none;
    }
    
    &.--multiline {
        white-space: normal;
        display: block;
        width: 100%;
        overflow: visible;

        > .deco {
            bottom: auto;
            top: -.1em;
        }
    }

    > .deco {
        position: absolute;
        display: block;
        bottom: .1em;
        left: 0;
        width: var(--width-deco);
        height: var(--width-deco);
        border-bottom: var(--border-size) solid currentColor;   

        .icon {
            position: absolute;
            display: block;
            bottom:.1em;
            left:.1em;
            text-align: right;
            width: 100%;
            height: 100%;
            transform: rotate(90deg);
            @include font-serif();
            line-height: 1;
        }
    }

    > .text {
        @include font-sans();
        line-height: .85;
        padding-left: .05em;
    }

    b {
        position: relative;
        font-weight: 400;
        color: var(--b-color);
        position: relative;
        &::before {
            content:'';
            z-index: -1;
            position: absolute;
            top:.15em;
            left:0;
            width: 100%;
            height: calc(100% - .3em);
            background-color: var(--b-bg);

            transform: scale3d(var(--b-size),1,1) translateX(var(--b-left));
            transition: transform .6s var(--ease-cuchillo-in-out);
            transform-origin: var(--transform-origin);
        }
    }
     
    &:hover {
     --pos: var(--pos-hover);
     --b-size: var(--hover-scale);
     --b-left: var(--hover-left);
     --b-color: var(--black);
     --b-bg: var(--green);
    }

    &.--active,
    &.__link-active {
        --pos: var(--pos-hover);
        pointer-events: none;
    }

    /// TOUCH
    @include isTouch() {
        overflow: visible;

        &:before {
            opacity: 0;
            transform: translateX(0);
        }

        > span {
            transform: translateX(0);
        }

        &:hover {
            &:before {
            opacity: 1;
            }
        }
    }
}

.link-with-label {
    @include link-with-label();
}
