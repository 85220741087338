@use "sass:math";

.block-slider-photos {
    --width: 100%;
    --height:80vh;
    --width-slide-empty:60vw;
    --gap: 0;

    @media (max-width: $smartphone) {
        --height:100vw;
    }

    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
}

.block-slider-photos {
    position: relative;
    width: var(--width);
    height: var(--height);
    user-select: none;
    margin-bottom: -1px;
    
    > .holder {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        gap: var(--gap);
    }

    cursor: grab;
    &:active {
        cursor: grabbing;
    }
}

.block-slider-photos__item:empty {
    width: var(--width-slide-empty);
    flex: 0 0 var(--width-slide-empty);
}

.block-slider-photos__item {
    position: relative;
    flex: 0 0 auto;
    width: auto;
    height: 100%;
    overflow: hidden;

    svg {
        height: 100%;
        width: auto;
    }

    figure {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }

    img {
        position: absolute;
        top:0;
        left:0;
        width: 110%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}
