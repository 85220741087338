#Logos {
  --top: calc(var(--grid) * 5);
  --left: calc(var(--grid) * 1);
  --width: calc(100% - var(--grid) * 2);
  --height: calc(100% - var(--grid) * 10);
  --color: var(--black);
  
  &.--white {
    --color: var(--white);
  }

  &.--inv {
    .logo-bear { display: none; }
    .logo-bear--inv { display: block; }
    .logo-rabe { display: none; }
  }

  &:not(.--inv).--rabe {
    .logo-bear { display: none; }
    .logo-bear--inv { display: none; }
    .logo-rabe { display: block; }
  }

  &:not(.--inv) {
    .logo-bear { display: block; }
    .logo-bear--inv { display: none; }
    .logo-rabe { display: none; }
  }

  position: fixed;
  @include z-index($z-index-logos);
  top: var(--top);
  left: var(--left);
  width: var(--width);
  height: var(--height);
  pointer-events: none;
  
  svg {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    fill: var(--color);
  }
}