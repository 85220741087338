html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--primary-bg);
    
    main {
        width: 100%;
    }
    
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap-out,
        .wrap {
            @include z-index($z-index-wrap);
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
            pointer-events: none;
        }
    }
    
    &.__scroll-manual {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

.masked-item {
    position: relative;
    opacity: 0;
    display: inline-block;
    margin-right: .1em;

    > .text {position: relative;}
    > .bg,
    > .mask {
        position: absolute;
        @include z-index(2);
        top:0;
        left:0;
        width: calc(100% + .05em);
        height: 115%;
        background-color: var(--primary-color);
        transform: scale3d(1,1,1);
        transform-origin: top right;
    }
}

.tp-dfwv {
    @include z-index(999999)
}