@use "sass:math";

#Cookies {
  --color: var(--primary-bg);
  --bg: var(--primary-color);
  --padding-v: var(--padding-xs);
  --padding-h: var(--padding-xs);
  --font-size-big: var(--font-size-s);
  --font-size: var(--font-size-s);
  --max-width: #{math.div(360px, 16px) * 1rem};
  --width-col: #{math.div(20px, 16px) * 1rem};

  &.__success {
    --bg: var(--color-sucess);
    --color: var(--black);
  }

  &.__error {
    --bg: var(--color-error);
    --color: var(--white);
  }

  &[aria-expanded='false'] {
    visibility: hidden;
    pointer-events: none;
  }
}


#Cookies {
  position: fixed;
  left: auto;
  right: var(--grid);
  bottom: var(--grid);
  max-width: var(--max-width);
  width: 100%;

  @include z-index($z-index-windows);

  display: flex;
  flex-wrap: wrap;
  padding: var(--padding-v) var(--padding-h);
  justify-content: space-between;
  align-items: center;

  background-color: var(--bg);
  color: var(--color);

  .content {
    flex: 1 0 var(--width-col);
    display: block;
    position: relative;
  }

  .controls {
    flex: 1 0 var(--width-col);
    padding-left: 10px;
    text-align: center;

    display: flex;
    justify-content: flex-end;
  }
}

//TEXTO
#Cookies p {
  @include font-sans();
  font-size: var(--font-size);
  margin: 0;
  padding-bottom: 0;

  b, strong {
    @include font-sans();
  }

  a {
    @include basic-a();
    text-decoration: underline;
    color: inherit;
  }
}


///BOTON
#Cookies button {
  @include basic-a();
  @include font-sans();
  line-height: 1;
  font-size: var(--font-size-big);
  letter-spacing: var(--letter-spacing);
  border: 1px solid var(--color);
  background: transparent;

  &:first-of-type {
    border-right: 0;
  }

  &:hover {
    background-color: var(--color);
    color: var(--bg);
  }
}

@media (max-width: $smartphone) {
  #Cookies {
    --max-width: calc(100% - 20px);

    left:10px;
    right: 0;
    bottom: 10px;
    padding: 20px 10px 10px;

    .content {
      width: 100%;
      margin-bottom: 0;
    }

    .controls {
      width: 100%;
      min-width: 100%;
      padding: 0;
      display: flex;
    }

    p {
      margin-bottom: 1em;
    }

    .btn-ok {
      width: 100%;
    }

    /*.btn-nok {
      margin: 0;
      width: 35%;
      padding: 15px 30px;
    }*/
  }
}
