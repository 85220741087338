@use "sass:math";

:root {
    --font-sans: 'NHaasGroteskTXPro-55Rg', sans-serif;
    --font-serif: 'Compagnon-Roman', sans-serif;
    --font-serif-light: 'Compagnon-Light', sans-serif;

    --font-size-xxl: #{math.div(360px, 16px) * 1rem};
    --font-size-xl: #{math.div(170px, 16px) * 1rem};
    --font-size-l: #{math.div(45px, 16px) * 1rem};
    --font-size-m: #{math.div(45px, 16px) * 1rem};
    --font-size-base: #{math.div(25px, 16px) * 1rem};
    --font-size-s: #{math.div(15px, 16px) * 1rem};
    --font-size-xs: #{math.div(12px, 16px) * 1rem};

    --line-height-sans: #{math.div(28, 25)};
    --line-height-serif: #{math.div(28, 25)};
    --letter-spacing: -0.06em;

    --zoom: 1;
    --font-size-fluid: calc(1.1111vw * var(--zoom));

    font-size: var(--font-size-fluid);

    @media (max-width: $smartphone) {
        font-size: 16px;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-serif($line-height:0) {
  font-family: var(--font-serif);
  font-weight: 400;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-serif);  }
}
@mixin font-serif-light($line-height:0) {
  font-family: var(--font-serif);
  font-weight: 200;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-serif);  }
}
